import firebase from "firebase/app";
require('firebase/auth')
import "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyCVj1TVOzfb3Z8fV3N16LeGhjS5guTzScg",
  authDomain: "huizerbouwer.firebaseapp.com",
  databaseURL: "https://huizerbouwer-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "huizerbouwer",
  storageBucket: "huizerbouwer.appspot.com",
  messagingSenderId: "960271355510",
  appId: "1:960271355510:web:cc72d61808377abc11d957",
  measurementId: "G-F2N5QMY5JZ"
};


var firebaseApp = firebase.initializeApp(firebaseConfig);

// Default only exported the database. But when auth needed, it could only fetch the database.
// Fixed by just changing the services and returning the whole firebase object. (could be split into multiple exports, but eh?)
export default firebaseApp;
